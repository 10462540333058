import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


export default function footer() {
  const dateNow = new Date();
  const year = dateNow && dateNow.getFullYear();

  return (<div className={"vw-footer"}>
    <Container fluid>
      <Row>
        <Container>
          <Row className="justify-content-between">
            <Col xs={12} sm={12} md={12} lg={4}>
              <a href="https://evergy.com.au/">
                <div
                  className="vw-footer-logo"
                >
                  <img
                    className="vw-footer-logo-img"
                    src="https://cdn.vostro.cloud/hotspots/evergy/evergy-logo-mono.svg"
                  />
                </div>
              </a>
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="footer-title-subcontent-1"
            >
              <div className="footer-title-empty">
                {/* {"Company"} */}
              </div>
              <a target="_blank" href="https://vost.ro/evergyspecialneeds">
                {"Special Needs and Disability"}
              </a><br/>
              <a target="_blank" href="https://vost.ro/evergyprivacypolicy">
                {"Privacy Policy"}
              </a>
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="footer-title-subcontent-2"
            >
              <div className="footer-title-empty" />
              <a target="_blank" href="https://vost.ro/evergycis">
                {"Critical Information Summary"}
              </a><br/>
              <a target="_blank" href="https://vost.ro/evergyterms">
                {"General Terms & Conditions"}
              </a><br/>
              <a target="_blank" href="/esafety">
                {"eSafety"}
              </a><br/>
              <a target="_blank" href="https://evergy.com.au/wp-content/uploads/2024/08/20240805-EVER9XX_Evergy-Payment-Assistance-Summary-v1.0.pdf">
                {"Payment Assistance"}
              </a><br/>
              <a target="_blank" href="https://evergy.com.au/internet_service_complaints/">
                {"Complaints Handling Policy"}
              </a>
            </Col>
            <Col className="footer-title-subcontent-1" xs={12} md={12} sm={12} lg={12}>
              <div className="footer-title">
                {"Customer Information"}
              </div>
              <a href="https://evergy.com.au/contact-us-internet/">
                {"Contact Us"}
              </a><br/>
              <a href="https://evergy.com.au/faq/">
                {"FAQ"}
              </a>
            </Col>
          </Row>
          <Row className="copywrite">
            <Col style={{textAlign: "center"}}>{`Copyright © ${year} Evergy - All Rights Reserved`}</Col>
          </Row>
        </Container>
      </Row>
    </Container>
  </div>);
}
